
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<!-- logout btn -->
			<v-layout row wrap>
				<v-flex xs6 lg4 xl4 md6 sm12>
					<!-- icon -->
					<h3>Welcome back to Elly Store</h3>
				</v-flex>
				<v-spacer></v-spacer>
                <!-- align to the right  -->
                <v-flex xs6 lg4 xl4 md6 sm12 style="text-align:right">
				<!-- <v-flex xs6 lg4 xl4 md6 sm12
                
                > -->
					<v-btn color="primary" @click="logout()" outlined> <v-icon>mdi-logout</v-icon>
						Logout</v-btn>
				</v-flex>
			</v-layout>
			<v-divider class="my-4"></v-divider>
			<v-container>
				<!-- {{customers}} -->
				<v-flex xs12 lg12 xl2 md12 sm12 class="mx-4">
					<p>Customer User Name: {{customers.customer_username}}</p>
					<p>Customer Phone Number: {{customers.customer_phone}}</p>
					<p>
						Customer 2nd Phone Number: {{customers.customer_phone_2}}
					</p>
					<p>Customer City: {{customers.customer_city}}</p>
					<p>
						Customer Address: {{customers.customer_address}}
					</p>
					<p>Customer Type: {{customers.customer_type}}</p>

				</v-flex>
				<hr class="mt-8">
			</v-container>
			<v-container>
				<!-- add a button to see previous orders -->
				<!-- <v-flex xs12 lg2 xl2 md3 sm4  class="mt-2 mb-10">
					<v-btn color="primary" @click="getOrders()" outlined>Show Orders</v-btn>

				</v-flex> -->
				<!-- <v-btn color="primary" @click="showOrders = !showOrders" v-show="showdata()" outlined>{{ $store.getters.language.data.orders.orders }}</v-btn> -->

				<!--  -->
			</v-container>
			<v-container>
				<v-layout row wrap mt-5>
					<v-flex xs12 v-show="showOrders">
						<!-- <v-card>
							<v-card-text>
								<v-data-table :headers="orderHeaders" :items="order_rows" class="elevation-0" item-key="order_id">

									<template v-slot:[`item.order_date`]="{ item }">
										<div>

											{{ new Date(item.order_date).toLocaleString() }}
										</div>
									</template>
									<template v-slot:[`item.order_packing_date`]="{ item }">
										<div>
											{{ item.order_packing_date ? new Date(item.order_packing_date).toLocaleString() : '' }}
										</div>
									</template>
									<template v-slot:[`item.order_shipping_price`]="{ item }">
										<div>
											${{ item.order_shipping_price }}
										</div>
									</template>
									<template v-slot:[`item.order_status`]="{ item }">
										<div>
											<v-icon v-if="item.order_status == 'packed'" color="green">mdi-bag-personal-outline</v-icon>
											<v-icon v-else color="red">mdi-bag-personal-off-outline</v-icon>
										</div>
									</template> -->

						<!-- <template v-slot:[`item.order_id`]="{ item }"> -->
						<!-- <div>/
                            <v-btn icon :to="'/orders-list/'+item.order_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectOrders(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div> -->
						<!-- </template> -->
						<!-- </v-data-table> -->
						<!-- </v-card-text> -->

						<!-- </v-card> -->
						<v-card>
							<v-card-title>
								<div>
									<p style="color:gray"> <v-icon>mdi-account-outline</v-icon> Customer Name: {{ order_rows.length > 0 ? order_rows[0].customer_username : '' }} </p>
								</div>
							</v-card-title>
							<v-card-text>
								<v-expansion-panels v-model="expandedPanelIndex">
									<v-expansion-panel v-for="(item, index) in order_rows" :key="index">
										<v-expansion-panel-header>
											<!-- Show order date -->
											<div>
												{{ new Date(item.order_date).toLocaleString() }}
											</div>
											<div>
												<!-- <v-icon v-if="item.order_status == 'packed'" color="green">mdi-bag-personal-outline</v-icon>
												<v-icon v-else color="red">mdi-bag-personal-off-outline</v-icon> -->
												<!-- if all order_items.order_item_status is equal to dilivered the show a btn -->
												<v-icon v-if="item.order_items.every(order_item => order_item.order_item_status == 'delivered')" color="green">mdi-bag-personal-outline</v-icon>
												<v-icon v-else color="red">mdi-bag-personal-off-outline</v-icon>
											</div>
											<div>{{item.order_items.length}}
												<v-icon>mdi-cart-outline</v-icon>
											</div>
										</v-expansion-panel-header>
										<v-expansion-panel-content>

											<v-table>

												<v-table-body>

													<!-- show item customer name -->
													<v-layout row wrap class="mt-5">
														<v-flex xs12 lg2 xl2 md12 sm12>
															<div>
																<v-icon>mdi-truck-delivery-outline</v-icon>
																Shipping Price:
																$ {{ item.order_shipping_price }}
															</div>
														</v-flex>
														<v-flex xs12 lg2 xl2 md12 sm12>
															<div v-if="item.order_total_price != null">

																<v-icon>mdi-cash-multiple</v-icon>
																Total Price:
																$ {{ item.order_total_price }}
															</div>
														</v-flex>
													</v-layout>
													<v-container>
													</v-container>
													<v-container>
														<v-form @submit.prevent="addOrderItem(item)">
															<v-layout row wrap>
																<v-flex xs10 lg4 xl4 md4 sm4>
																	<v-text-field v-model="orderItem.order_sku_code" :label="$store.getters.language.data.order_items.order_sku_code" filled outlined dense required></v-text-field>
																</v-flex>
																<v-flex xs2 lg2 xl2 md3 sm4 style="text-align:centered">
																	<v-btn @click="pasteText" small icon><v-icon>mdi-content-paste</v-icon></v-btn>
																</v-flex>
																<!-- <v-flex xs12 lg12 xl12 md12 sm12> -->
																<!-- </v-flex> -->
															</v-layout>
															<v-layout row wrap style="justify-content: space-between;">

																<v-flex xs12 lg2 xl2 md3 sm3>
																	<v-text-field v-model="orderItem.order_item_quantity" type="number" label="Quantity" filled outlined dense required disabled></v-text-field>
																</v-flex>
																<v-flex xs12 lg3 xl3 md4 sm4>
																	<v-text-field v-model="orderItem.order_item_price" prepend-inner-icon="mdi-currency-usd" :label="$store.getters.language.data.order_items.order_item_price" filled outlined dense required>
																	</v-text-field>
																</v-flex>
																<v-flex xs12 lg3 xl3 md4 sm4>
														
                                                                    <v-text-field v-model="orderItem.order_item_note" :label="$store.getters.language.data.order_items.order_item_note" filled outlined dense></v-text-field>
																</v-flex>
																<v-flex xs12 lg3 xl3 md4 sm4>
																	<v-row no-gutters>
																		<v-text-field v-model="orderItem.order_item_link" :label="$store.getters.language.data.order_items.order_item_link" filled outlined dense></v-text-field>
																		<v-btn @click="pasteTextLink" small icon><v-icon>mdi-content-paste</v-icon></v-btn>
																	</v-row>
																</v-flex>
																<v-flex xs2 lg2 xl2 md3 sm4 style="text-align:centered">
																</v-flex>
																<v-flex xs12 lg3 xl3 md4 sm4>
																	<v-btn color="primary" type="submit" width="100%"><v-icon>mdi-content-save-check-outline</v-icon>Add</v-btn>
																</v-flex>

															</v-layout>
														</v-form>
													</v-container>
													<!-- add a button to show new form to add new item to the order -->
													<br>
													<v-table v-for="(item, index) in item.order_items" :key="index">
														<!-- write the index of the item in dark gray with # -->
														<v-table-cell class="text-xs-left" style="color:gray">#{{ index + 1 }} </v-table-cell>
														<v-table-cell><a :href="item.order_item_link" target="_blank"><v-icon v-on="on" v-if="item.order_item_link != null">mdi-link</v-icon></a><br></v-table-cell>

														<v-table-cell>SKU : {{ item.order_sku_code }}<br></v-table-cell>
														<v-table-cell>Quantity: {{ item.order_item_quantity }}<br></v-table-cell>
														<v-table-cell>Price: $ {{ item.order_item_price }}<br></v-table-cell>
														<v-table-cell v-if="item.order_item_note != null">Note: {{ item.order_item_note }}<br></v-table-cell>
														<v-table-cell v-if="item.order_item_status != null">Status: {{ item.order_item_status }}<br></v-table-cell>
														<!-- add btn to reinsert the item -->
														<v-table-cell>
															<v-btn color="primary" @click="reinsertItem(item)" small><v-icon>mdi-restore</v-icon></v-btn>
														</v-table-cell>

														<v-divider class="pa-2 mt-3"></v-divider>
													</v-table>
												</v-table-body>
											</v-table>

										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</v-card-text>
						</v-card>

					</v-flex>
				</v-layout>

			</v-container>

		</v-container>

		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../requests/customers.request.js'
	import requestsOrder from './../requests/orders.request.js'
	import requestsOrderItems from './../requests/order_items.request.js'

	export default {
		data() {
			return {
				expandedPanelIndex: 0,

				orders: {},
				order_items: {},
				customer_id: '',
				orderItem: {
					order_sku_code: '',
					order_item_quantity: 1,
					order_item_link: '',
				},
				customers: {
					customer_username: '',
				},
				showOrders: false,
				showCustomShippingPrice: false,
				search: '',
				loading: true,
				selected_users: false,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				showAddItemForm: false,
				rows: [],
				order_rows: [],
				selected_customers: {},
				orderHeaders: [


					{
						text: this.$store.getters.language.data.orders.order_date,
						align: 'start',
						sortable: true,
						value: 'order_date',
					},
					{
						text: this.$store.getters.language.data.orders.order_shipping_price,
						align: 'start',
						sortable: true,
						value: 'order_shipping_price',
					},
					{
						text: this.$store.getters.language.data.orders.order_status,
						align: 'start',
						sortable: true,
						value: 'order_status',
					},
					{
						text: this.$store.getters.language.data.orders.order_packing_date,
						align: 'start',
						sortable: true,
						value: 'order_packing_date',
					},

					{
						text: this.$store.getters.language.data.orders.order_note,
						align: 'start',
						sortable: true,
						value: 'order_note',
					}
				],


			}
		},
		computed: {
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			}

		},

		mounted() {
			this.customer_id = this.$route.params.customer_id
			this.customers.customer_id = this.customer_id
			this.customers.user_id = this.user.user_id
			this.getOrders();
			this.readCustomers()
		},
		methods: {
			logout() {
				this.$store.dispatch('logout');
			},
			pasteText() {
				navigator.clipboard.readText().then(pastedText => {
					this.orderItem.order_sku_code = pastedText;

				});
			},
			pasteTextLink() {
				navigator.clipboard.readText().then(pastedText => {
					this.orderItem.order_item_link = pastedText;
				});

			}
			,
			reinsertItem(item) {
				this.orderItem = item
				delete this.orderItem.order_item_id
				delete this.orderItem.order_item_date
				delete this.orderItem.order_item_purchase_date
				this.orderItem.order_item_quantity = 1

			},
			clear() {
				this.customers = {
					customer_username: '',
					customer_phone: '',
					customer_phone_2: '',
					customer_city: '',
					customer_address: '',
					user_id: this.user.user_id
				},
					this.customers.customer_username = ''
				this.selected_users = false

			},
			showData() {
				// console.log(this.customers)
				if (this.customers.customer_id && this.customers.customer_username && this.customers.customer_phone && this.customers.customer_city && this.customers.customer_address) {
					console.log("true")
					return true
				} else {
					return false
				}
			},


			handleInput(newInput) {
				//if the customer name is selected from the list then change all the customer object values to the one selected 

				if (newInput) {
					console.log(newInput)
					console.log(newInput.customer_phone)
					this.customers.customer_id = newInput.customer_id
					this.customers.customer_username = newInput
					this.customers.customer_phone = newInput.customer_phone
					this.customers.customer_phone_2 = newInput.customer_phone_2
					this.customers.customer_city = newInput.customer_city
					this.customers.customer_address = newInput.customer_address
					this.customers.customer_type = newInput.customer_type
					// if (customers.customer_phone) {
					// this.selected_users = true
					console.log("========================")
					// }
				}


			},
			addCustomers() {
				this.loading_btn = true
				requests.createCustomers(this.customers).then(r => {
					if (r.status == 200) {
						// this.customers = {}
						// this.customers.customer_username = ''
						//call the show data function and return true 

						this.customers.user_id = this.user.user_id
						this.rows.push(
							r.data.new_data
						)
						//check the customer_username from the customers and if its in the rows then console log it
						if (this.rows.some(e => e.customer_username === this.customers.customer_username)) {
							console.log("customer_username is in the rows")
							//print the customer_username found in the rows
							console.log(this.rows.find(e => e.customer_username === this.customers.customer_username))
							//equal the this.customers.customer_id to the customer_id found in the rows
							this.customers.customer_id = this.rows.find(e => e.customer_username === this.customers.customer_username).customer_id
						} else {
							console.log("customer_username is not in the rows")
						}
						console.log(this.customers.customer_id)
						this.snackbar = {
							value: true,
							text: 'Customers Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add Customers',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},




			readCustomers() {
				this.loading = true
				requests.getOneCustomers(this.customer_id).then(r => {
					if (r.status == 200) {
						console.log(r.data.row.customer_address)
						this.customers = r.data.row
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Customers',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Customers',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},

			addOrderItem(item) {
				this.loading_btn = true
				console.log(item)
				console.log("====================================")
				this.orderItem.order_id = item.order_id
				this.orderItem.order_item_status = "pending"
				// order_item_date is three days before the current date
				// this.orderItem.order_item_date = new Date(new Date().setDate(new Date().getDate() - 2)).toISOString().slice(0, 19).replace('T', ' ');
				// this.orderItem.order_item_date = new Date(new Date(this.orderItem.order_item_date).setHours(new Date(this.orderItem.order_item_date).getHours() - 12)).toISOString().slice(0, 19).replace('T', ' ');

				this.orderItem.user_id = this.user.user_id
				console.log(this.orderItem.order_item_link)
				if (this.orderItem.order_item_link == "") {
					this.orderItem.order_item_link = null
				}
				else if (this.orderItem.order_item_link != null && this.orderItem.order_item_link != "") {
					// split the order_item_link and get the last part of the link starting from http and http included
					this.orderItem.order_item_link = this.orderItem.order_item_link.split("http")[1]
					// add http to the order_item_link
					this.orderItem.order_item_link = "http" + this.orderItem.order_item_link
					console.log(this.orderItem.order_item_link)
				}


				console.log(this.orderItem)
				requestsOrderItems.createOrderItems(this.orderItem).then(r => {
					if (r.status == 200) {

						this.order_rows.find(e => e.order_id === this.orderItem.order_id).order_items.push(r.data.new_data)

						this.orderItem = {
							user_id: this.user.user_id,
							order_sku_code: '',
							order_item_link: '',
							order_item_quantity: 1,

						}


						this.snackbar = {
							value: true,
							text: 'OrderItems Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add OrderItems',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			addOrders() {
				this.orders.user_id = this.user.user_id
				this.orders.customer_id = this.customers.customer_id
				this.orders.order_status = "unpacked"
				//equal order.order_date to the current date and time
				// this.orders.order_date = new Date().toISOString().slice(0, 19).replace('T', ' ');

				this.loading_btn = true
				requestsOrder.createOrders(this.orders).then(r => {
					if (r.status == 200) {
						this.orders = {}
						this.getOrders()
						//push the new data to the beggining of order_rows array
						// this.order_rows.unshift(
						//     r.data.new_data
						// )


						// this.order_rows.push(
						// 	r.data.new_data
						// )
						this.orders.user_id = this.user.user_id
						this.snackbar = {
							value: true,
							text: 'Orders Added',
							color: 'success'
						}
						console.log("=================this.orders==================");
						console.log(this.orders);

						console.log("=================ORDER ROWS==================");
						console.log(this.order_rows);

					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add Orders',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},

			// getOrders() {
			// 	this.loading = true
			// 	requestsOrder.reportOrders("customer_id", this.customers.customer_id).then(r => {
			// 		if (r.status == 200) {
			// 			this.order_rows= r.data
			//             console.log("=================ORDER ROWS==================");
			//             console.log(r.data);
			//             console.log(this.order_rows);
			// 			this.loading = false
			//             this.showOrders = true
			// 		} else {
			// 			this.snackbar = {
			// 				value: true,
			// 				text: 'Fail to read Orders',
			// 				color: 'error'
			// 			}
			// 		}
			// 	})
			// 		.catch(e => {
			// 			this.snackbar = {
			// 				value: true,
			// 				text: 'Fail to read Orders',
			// 				color: 'error'
			// 			}
			// 		})
			// 		.finally(() => {
			// 			this.loading = false
			// 		})
			// },

			getOrders() {
				this.loading = true
				requestsOrder.reportOrders("customer_id", this.customers.customer_id).then(r => {
					if (r.status == 200) {
						this.order_rows = r.data.rows.rows
						console.log("=================ORDER ROWS==================");

						this.loading = false
						this.showOrders = true
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Orders',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Orders',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},


		},
	}
</script>
                    